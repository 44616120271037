import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['parentFormSection'];

  connect() {
    this.toggleVisibility();
  }

  toggleVisibility() {
    const isChecked = this.checkbox.checked;
    if (isChecked) {
      this.parentFormSectionTarget.classList.remove('tw-hidden');
    } else {
      this.parentFormSectionTarget.classList.add('tw-hidden');
    }
  }

  get checkbox() {
    return this.element.querySelector("input[type='checkbox']");
  }
}
